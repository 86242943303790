<template>
    <div>
        <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
            <span v-if="snackbar_msg!=null">{{ snackbar_msg }}</span>
            <v-btn dark text @click="snackbar = false">Close</v-btn>
        </v-snackbar>
        <v-row class="bradcrumb">
            <router-link :to="{name: 'home' }" class="root">
                Dashboard
            </router-link>
            <!-- /
            <router-link :to="{name: 'admin-master' }" class="root">
                Admin Master
            </router-link> -->
            <span class="child">/ Organization </span>
        </v-row>
        <br />
        <v-card class="card">
            <v-card-title class="heading justify-center">Import Organization Details</v-card-title>
            <v-card-text>
                <div class="add-section">
                    <v-row class="justify-content-between set-btn">
                        <div>
                            <v-btn class="mb-2 button"> <v-icon left dark>mdi-cloud-download</v-icon>Export CSV Template</v-btn>
                        </div>
                        <div>
                            <v-btn class="mb-2 button" @click="addOrg">Add Organization</v-btn>
                        </div>
                    </v-row>
                    <p class="file-warn">NOTE: *File should be in CSV format.</p>

                    <v-row class="justify-content-center">
                        <v-col lg="5" sm="12" md="5" class="import-box">
                             <label class="label">Select Tenant</label>
                             <v-autocomplete
                                v-model="tenantimport"
                                :items="tenantlist"
                                item-text="tc_name"
                                item-value="tc_id"
                               
                            ></v-autocomplete>
                            <label class="label">IMPORT CSV</label>
                            <v-file-input
                                dense
                                v-model="file"
                                :rules="[v => !!v || 'File required']"
                                persistent-hint
                                color="rgb(1, 127, 152)"
                                label="Choose File"
                                accept=".csv"
                                prepend-icon="mdi-paperclip"
                                :error-messages="!fileStatus?'File Required':''"
                                outlined
                                show-size
                            >
                            </v-file-input>
                            <v-btn  @click="importCsv" class="button">
                                Import
                                <v-icon right>mdi-cloud-upload</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                </div>

                <template>
                    <v-simple-table v-if="failList != null">
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th class="text-left">
                                        Name
                                    </th>
                                    <th class="text-left">Display name</th>
                                    <th class="text-left">Tenant name</th>
                                    <th class="text-left">Contact Person Name</th>
                                    <th class="text-left">Domain</th>
                                    <th class="text-left">Reason</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="item in failList" :key="item.name">
                                    <td>{{ item.name }}</td>
                                    <td>{{ item.displayname }}</td>
                                    <td>{{ item.tenantname }}</td>
                                    <td>{{ item.cp_name }}</td>
                                    <td>{{ item.domain }}</td>
                                    <td style="color: red;">{{ item.reason }}</td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </template>
                <br>
                    <v-autocomplete
                    v-model="tenant"
                    :items="tenantlist"
                    item-text="tc_name"
                    item-value="tc_id"
                    @change="onLoad()"
                    label="Select Tenant to Show Organization" filled
                    outlined
                ></v-autocomplete>`
                 <v-simple-table fixed-header height="300px">
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">Sr. No.</th>
                        <th class="text-left">Library</th>
                        <th class="text-left">Name</th>
                        <th class="text-left">Org Code</th>
                        <th class="text-left">Display Name</th>
                        <th class="text-left">Trust Name</th>
                        <th class="text-left">Tenant Name</th>
                        <th class="text-left">Address</th>
                        <th class="text-left">Contact Person Name</th>
                        <th class="text-left">Email</th>
                        <th class="text-left">Contact</th>
                        <th class="text-left">Attachment Domain</th>
                        <th class="text-left">Collage Website</th>
                        <th class="text-left">ERP Website</th>
                        <th class="text-left">Country</th>
                        <th class="text-left">Active</th>
                        <th class="text-left">Edit</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item,i) in org_list" :key="item.name">
                        <td>{{ i+1 }}</td>
                        <td>{{ item.lib_name }}</td>
                        <td>{{ item.name }}</td>
                        <td>{{ item.org_code }}</td>
                        <td>{{ item.display_name }}</td>
                        <td>{{ item.trust_name }}</td>
                        <td>{{ item.tc_name }}</td>
                        <td>{{ item.address }}</td>
                        <td>{{ item.cp_name }}</td>
                        <td>{{ item.email }}</td>
                        <td>{{ item.contact }}</td>
                        <td>{{ item.domain }}</td>
                        <td>{{ item.website }}</td>
                        <td>{{ item.erpwebsiteurl }}</td>
                        <td>{{ item.country }}</td>
                        <td>
                            <span><v-switch v-model="item.isactive" @change="activeOn(item)"></v-switch></span>
                        </td>
                        <td>
                            <span><v-icon @click="editItem(item)">mdi mdi-pencil-box</v-icon></span>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
            </v-card-text>
        </v-card>
        <v-overlay :value="overlay">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <!-- add -->
        <v-dialog v-model="dialog" max-width="700px">
            <v-card>
                <v-form  ref="form">
                    <v-card-title class="heading">
                        <span class="headline">{{ formTitle }}</span>
                    </v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-row>
                                <v-col   cols="6" sm="6" md="6"  >
                                    <v-autocomplete
                                        v-model="editedItem.selectedtenant"
                                        :items="tenantlist"
                                        item-text="tc_name"
                                        item-value="tc_id"
                                        return-object
                                        label="Select Tenant" filled
                                        :rules="[rules.required]" clearable required
                                        @change="getLibrary()"
                                    ></v-autocomplete>
                                </v-col>
                                <v-col   cols="6" sm="6" md="6"  >
                                    <v-autocomplete
                                        v-model="editedItem.library"
                                        :items="librarylist"
                                        item-text="lib_name"
                                        item-value="lib_id"
                                        return-object
                                        label="Select Library" filled
                                        :rules="[rules.required]" clearable required
                                    ></v-autocomplete>
                                </v-col>
                                <v-col cols="6" sm="6" md="6" >
                                    <v-text-field
                                        label="Organization Name" filled
                                        v-model="editedItem.name"
                                        item-text="temp_id"
                                        item-value="id"
                                        persistent-hint
                                        :error="errorMsg.name?true:false"
                                        :error-messages="errorMsg.name?'Required':''"
                                        :rules="[v => !!v || 'required']"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="6" sm="6" md="6" >
                                    <v-text-field
                                        label="Organization Code" filled
                                        v-model="editedItem.org_code"
                                        persistent-hint
                                        :error="errorMsg.org_code?true:false"
                                        :error-messages="errorMsg.org_code?'Required':''"
                                        :rules="[v => !!v || 'required']"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="6" sm="6" md="6" >
                                    <v-text-field
                                        label="Display Name" filled
                                        v-model="editedItem.display_name"
                                        item-text="temp_id"
                                        item-value="id"
                                        persistent-hint
                                        :error="errorMsg.display_name?true:false"
                                        :error-messages="errorMsg.display_name?'Required':''"
                                        :rules="[v => !!v || 'required']"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="6" sm="6" md="6" >
                                    <v-text-field
                                        label="Trust Name" filled
                                        v-model="editedItem.trust_name"
                                    ></v-text-field>
                                </v-col>
                                <!-- <v-col cols="12" sm="12" md="12" class="fields">
                                    <label>Tenant name</label>
                                    <v-text-field
                                        dense
                                        outlined
                                        v-model="editedItem.tenant_name"
                                        item-text="temp_id"
                                        item-value="id"
                                        persistent-hint
                                        :error="errorMsg.tenant_name?true:false"
                                        :error-messages="errorMsg.tenant_name?'Required':''"
                                        :rules="[v => !!v || 'required']"
                                    ></v-text-field>
                                </v-col> -->
                                <v-col cols="6" sm="6" md="6" >
                                    <v-text-field  label="Address" filled v-model="editedItem.address" item-text="temp_id" item-value="id" persistent-hint></v-text-field>
                                </v-col>

                                <v-col cols="6" sm="6" md="6" >
                                    <v-text-field
                                        label="Contact person name" filled
                                        v-model="editedItem.cp_name"
                                        item-text="temp_id"
                                        item-value="id"
                                        persistent-hint
                                        :error="errorMsg.cp_name?true:false"
                                        :error-messages="errorMsg.cp_name?'Required':''"
                                        :rules="[v => !!v || 'required']"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="6" sm="6" md="6" >
                                    <v-text-field label="Contact" filled  v-model="editedItem.contact" item-text="temp_id" item-value="id" persistent-hint></v-text-field>
                                </v-col>
                                <v-col cols="6" sm="6" md="6" >
                                    <v-text-field label="Email" filled  v-model="editedItem.email" item-text="temp_id" item-value="id" persistent-hint></v-text-field>
                                </v-col>
                                
                                <v-col cols="6" sm="6" md="6" >
                                    <v-text-field label="Collage Website" filled  v-model="editedItem.website" item-text="temp_id" item-value="id" persistent-hint></v-text-field>
                                </v-col>
                                <v-col cols="6" sm="6" md="6" >
                                    <v-text-field label="ERP Website Url" filled  v-model="editedItem.erpwebsiteurl" item-text="temp_id" item-value="id" persistent-hint></v-text-field>
                                </v-col>
                                <v-col cols="6" sm="6" md="6" >
                                    <v-text-field  label="Country" filled v-model="editedItem.country" item-text="temp_id" item-value="id" persistent-hint></v-text-field>
                                </v-col>
                                <v-col cols="6" sm="6" md="6" >
                                    <v-text-field
                                        label="Attachment Domain"
                                        filled
                                        v-model="editedItem.domain"
                                        item-text="temp_id"
                                        item-value="id"
                                        :error="errorMsg.domain?true:false"
                                        :error-messages="errorMsg.domain?'Required':''"
                                        :rules="[v => !!v || 'required']"
                                    ></v-text-field>
                                </v-col>
                                <!-- <v-col cols="12" sm="12" md="12" class="fields">
                                    <label>Logo Image</label>
                                <v-file-input placeholder="Select Logo Image" outlined dense  prepend-icon="mdi-file" show-size v-model="file1" accept="image/jpeg"></v-file-input>
                                </v-col> -->
                            </v-row>
                        </v-container>
                    </v-card-text>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn class="button-cancel" text @click="close">Cancel</v-btn>
                        <v-btn class="button" @click="save" >Save</v-btn>
                    </v-card-actions>
                </v-form>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import axios from "axios";
    import "@/assets/css/color.css"; // css needs to be imported for each dashboard
    export default {
        data: () => ({
            valid: false,
            tenantlist:null,
            overlay: false,
            errorMsg: {
                roleLinkId: false,
            },
            failList: null,
            snackbar_msg: "",
            color: "",
            snackbar: false,
            search: "",
            org: "",
            file: null,
            communicationMethod: [],
            inSave: true,
            dialog: false,
            tenantimport:null,
            
            fileStatus: true,
            org_list: null,
            editedIndex: -1,
            librarylist: [],
            editedItem: {
                name: "",
                org_code: "",
                display_name: "",
                trust_name: "",
                tc_name: "",
                address: "",
                cp_name: "",
                email: "",
                contact: "",
                domain: "",
                website: "",
                erpwebsiteurl:"",
                country: "",
                isactive: false,
                id: "",
                selectedtenant: null,
                library: "",
            },
             rules: {
                required: value => !!value || "Required."
                        },
            file1:null,
            defaultItem: {
                name: "",
                org_code: "",
                display_name: "",
                trust_name: "",
                tc_name: "",
                address: "",
                cp_name: "",
                email: "",
                contact: "",
                domain: "",
                website: "",
                country: "",
                isactive: false,
                id: "",
                selectedtenant: null,
                erpwebsiteurl: "",
            },
            tenant:null
        }),

        computed: {
            formTitle() {
                return this.editedIndex === -1 ? "New Organization" : "Edit Organization";
            },
        },

        // watch: {
        //     dialog(val) {
        //         val || this.close();
        //     },
        //     overlay(val) {
        //         val = () => {
        //             this.overlay = false;
        //         };
        //     },
        // },
        mounted() {
            this.onLoad();
        },
        methods: {
            importCsv() {
                // const data = {
                //     data:'data fake'
                // }
                let formData = new FormData();
                if (this.file !== null) {
                   if (!(this.file.name.toLowerCase().includes(".csv") || this.file.name.toLowerCase().includes(".CSV"))) {
                       this.showSnackbar("red", "File must be in CSV format Only.!");
                   } else{
                        this.fileStatus = true;
                        formData.append("file", this.file);
                        formData.append("tenant", this.tenantimport)
                        axios
                            .post("SuperAdmin/importOrg", formData, {
                                headers: {
                                    "Content-Type": "multipart/form-data",
                                },
                            })
                            .then((res) => {
                                this.failList = res.data.failList;
                                if (res.data.status == "200") {
                                    this.showSnackbar("#4caf50", "Organizations added successfully...");
                                    this.onLoad();
                                }
                                this.file = null;
                            })
                            .catch(function () {
                                this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
                            })
                            .finally(() => {
                                //this.output_load = false;
                            });
                   }
                } else {
                    this.fileStatus = false;
                    this.showSnackbar("#b71c1c", "Please select file");
                }
            },
            addOrg() {
                this.inSave = true;
                this.dialog = true;
                //this.$refs.form.reset();
            },
            editItem(item) {
                this.inSave = false;
                this.editedIndex = this.org_list.indexOf(item);
                this.editedItem = Object.assign({}, item);
                this.dialog = true;

                 // Ensure you're setting the entire tenant/library object, not just the id
                this.editedItem.selectedtenant = this.tenantlist.find(t => t.tc_id === item.tc_id);
                // this.editedItem.library = this.librarylist.find(l => l.lib_id === item.lib_id);
                this.getLibrary(this.editedItem.selectedtenant)
                // var name = item.role;
                // for (let x in this.role_list) {
                //     if (this.role_list[x].name == name) {
                //         this.editedItem.role = this.role_list[x].name;
                //         this.editedItem.roleId = this.role_list[x].id;
                //         break;
                //     }
                // }
            },
            onLoad() {
                //this.overlay = true;
                const data={
                    tenant: this.tenant
                };
                axios
                    .post("/SuperAdmin/getOrgData",data)
                    .then((res) => {
                        
                        if (res.data.msg == "200") {
                            this.overlay = false;
                            this.tenantlist = res.data.tclist;
                            this.org_list = res.data.org_list;
                            //alert(this.org_list.length)
                            this.communicationMethod = res.data.communicationMethod;
                            this.notifyTypes = res.data.notifyTypes;
                        }
                    })
                    .catch((error) => {
                        this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
                        window.console.log(error);
                    })
                    .finally(() => {
                        // var overlay = false;
                    });
            },
            showSnackbar(clr, msg) {
                this.snackbar = true;
                this.color = clr;
                this.snackbar_msg = msg;
            },
            close() {
                this.dialog = false;
                setTimeout(() => {
                    this.editedItem = Object.assign({}, this.defaultItem);
                    this.editedIndex = -1;
                }, 300);
            },

            save() {
                if (this.editedIndex > -1) {
                    if (this.$refs.form.validate()) {
                        axios
                            .post("/SuperAdmin/editOrg", this.editedItem)
                            .then((res) => {
                                if (res.data.status.code == "SUCCESS") {
                                    //window.alert(res.data.msg)
                                    Object.assign(this.org_list[this.editedIndex], this.editedItem);
                                    this.showSnackbar("#4caf50", "Organization Updated Successfully..."); // show snackbar on success
                                    this.onLoad();
                                } else if (res.data.status.code == "NA") {
                                    this.showSnackbar("#b71c1c", "Organization Already Present!!!"); // show snackbar on error
                                }
                            })
                            .catch((error) => {
                                window.console.log(error);
                            });
                        this.close();
                    }
                } else {
//                     formData.append("address", this.editedItem.address);
//                     formData.append("contact", this.editedItem.contact);
//                     formData.append("country", this.editedItem.country);
//                     formData.append("cp_name", this.editedItem.cp_name);
//                     formData.append("display_name", this.editedItem.display_name);
//                     formData.append("domain", this.editedItem.domain);
//                     formData.append("email", this.editedItem.email);
//                      formData.append("file", this.file1);
//  formData.append("name", this.editedItem.name);
//  formData.append("tenant_name", this.editedItem.tenant_name);
//  formData.append("website", this.editedItem.website);

                    axios
                        .post("/SuperAdmin/saveOrg", this.editedItem)
                        //   .post("Admin/saveOrg", formData, {
                            // headers: {
                            //     "Content-Type": "multipart/form-data",
                            // },
                        // })
                        .then((res) => {
                            if (res.data.status.code == "SUCCESS") {
                                this.showSnackbar("#4caf50", "Organization Added Successfully!!!"); // show snackbar on success
                                this.onLoad();
                            } else if (res.data.status.code == "NA") {
                                this.showSnackbar("#b71c1c", "Organization Already Present!!!"); // show snackbar on error
                            }
                        })
                        .catch((error) => {
                            window.console.log(error);
                        });
                    this.close();
                }
            }, //save()

            activeOn(item) {
                console.log(item);
                const data={
                   item:item,
                   tenant: this.tenant     
                };
                axios
                    .post("/SuperAdmin/orgIsactive", data)
                    .then(() => {
                        // this.sec_mode_list = res.data.user_list;
                        this.showSnackbar("#4CAF50", "Organization updated successfully...");
                    })
                    .catch((error) => {
                        this.showSnackbar("#b71c1c", "Something went wrong!!!");
                        console.log(error);
                    })
                    .finally(() => {
                        this.edit_btn_load = false;
                        axios;
                    });
            },

            isValidated() {
                if (this.editedItem.roleLinkId) {
                    return true;
                } else {
                    if (!this.editedItem.roleLinkId) {
                        this.errorMsg.roleLinkId = true;
                    }

                    return false;
                }
            }, // .....end of isValidated()

            getLibrary(item) {
                //this.overlay = true;
                // alert(this.editedItem.selectedtenant);
                var obj
                if(item){
                    obj = item.tc_id;
                }
                else{
                 obj = this.editedItem.selectedtenant.tc_id;
                }
               
                const data={
                    tenant: obj,
                };
                axios
                    .post("/SuperAdmin/getLibrary",data)
                    .then((res) => {
                        
                        if (res.data.msg == "200") {
                            this.librarylist = res.data.librarylist;
                        }
                    })
                    .catch((error) => {
                        this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
                        window.console.log(error);
                    })
                    .finally(() => {
                        // var overlay = false;
                    });
            },
        },
    };
</script>
<style scoped>
    .v-text-field {
        padding-top: 0px;
        margin-top: 0px;
    }
    .v-icon-size {
        font-size: 20px;
    }
    .edit-avatar {
        transition: 0.3s;
        border: 1px solid #b0bec5;
    }
    .edit-avatar:hover {
        background-color: #b0bec5;
    }
    .edit-v-icon {
        padding-left: 6px;
        font-size: 15px;
    }
    .edit-v-icon:hover {
        color: white;
    }
    .fields {
        padding: 0px !important;
    }
    .add-section {
    display: block;
}
    /* .add-section {
        display: block;
    }
    .import-box {
        margin: 1rem 1rem 2rem 1rem;
        border: 2px solid;
        padding: 1rem 2rem 1rem 2rem;
        text-align: center;
        background: #ffffffb5;
    }
    .label {
        color: black;
        font-size: 16px;
        font-weight: 400;
    }
    .button {
        background: white !important;
    }
    .set-btn {
        margin: 0rem 1rem 0rem 1rem;
        padding-top: 1rem;
    } */
    .button:hover {
        color: white;
        background: black !important;
    }

</style>
